import { CSSProperties, Fragment, useMemo } from 'react';
import {
  BooleanInput,
  TextInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  NumberInput,
  maxValue,
} from 'react-admin';

import {
  EditableEventFeatureFlag,
  EditableEventFeatureLimit,
  pricingPlanFeatureConfigKeys,
} from '@/types';

import { FeatureConfigInputRecord, OVERRIDE_FIELD_NAME } from './types';
import { constructInputComponent } from './constructInputComponent';
import { pick } from 'lodash-es';
import { JsonInput } from './JsonInput';

export { OVERRIDE_FIELD_NAME };

const BooleanConfigInput = constructInputComponent(BooleanInput);
const TextConfigInput = constructInputComponent(TextInput);
const JSONConfigInput = constructInputComponent(JsonInput);
const AutocompleteConfigInput = constructInputComponent(AutocompleteInput);
const AutocompleteArrayConfigInput = constructInputComponent(
  AutocompleteArrayInput,
);
const NumberConfigInput = constructInputComponent(NumberInput);

interface Props {
  record?: FeatureConfigInputRecord;
  hasOverrideToggle?: boolean;
  source: string;
  mode: 'event' | 'pricing-plan';
  isDisabled?: boolean;
}

const validateMaxNumberOfNetworkingLoungeSeatsPerTable = [maxValue(50)]; // ref: https://www.twilio.com/docs/video/api/rooms-resource#post-list-resource

export const FeatureConfigInput: React.FC<Props> = ({
  record,
  hasOverrideToggle,
  source,
  mode,
  isDisabled,
}) => {
  const commonInputProps = useMemo(
    () => ({
      record,
      isDisabled,
      hasOverrideToggle,
      source,
    }),
    [hasOverrideToggle, isDisabled, record, source],
  );

  const defaultEventFeatureFlagComponents = useMemo<
    Record<keyof EditableEventFeatureFlag, JSX.Element>
  >(
    () => ({
      isAttendeeActivityEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeActivityEnabled"
          label="Enable Attendee Activity"
        />
      ),
      isChatEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isChatEnabled"
          label="Enable Chat"
        />
      ),
      isCreditSystemOutreachManualEmailEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCreditSystemOutreachManualEmailEnabled"
          label="Enable Credit System for Outreach Manual Email"
        />
      ),
      isAnnouncementDisabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAnnouncementDisabled"
          label="Disable Announcement"
        />
      ),
      isWebinarRealtimeQnAEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWebinarRealtimeQnAEnabled"
          label="Webinar Realtime QnA Enabled"
          helperText="Revamped Webinar QnA and Question Screening"
        />
      ),
      isWhatsappOutgoingMessageEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWhatsappOutgoingMessageEnabled"
          label="Whatsapp Enabled"
          helperText="Enable Whatsapp feature"
        />
      ),
      isAnalyticsDataSourceFootprintEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAnalyticsDataSourceFootprintEnabled"
          label="Analytics DataSource Footprint"
        />
      ),
      isSmsEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isSmsEnabled"
          label="SMS Notification"
        />
      ),
      isPushNotificationEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isPushNotificationEnabled"
          label="Push Notification"
        />
      ),
      isBoothFormSubmissionEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isBoothFormSubmissionEnabled"
          label="Booth Form Submission"
        />
      ),
      isAutoSignUpEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAutoSignUpEnabled"
          label="Attendee Auto Sign Up"
          helperText="enable register attendee even it is not signed in"
        />
      ),
      isAiEngineTDCEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAiEngineTDCEnabled"
          label="HKTDC AI engine"
          helperText="enable the AI Recommendation for hktdc event"
        />
      ),
      isSpeakerOnboardingInstantlyEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isSpeakerOnboardingInstantlyEnabled"
          label="Speaker Onboarding instantly"
          helperText="enable host invite speaker and ensure a smooth onboard from webinar."
        />
      ),
      isAttendeeRsvpInvitationEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeRsvpInvitationEnabled"
          label="Attendee Rsvp Invitation"
          helperText="Allow attendee to use rsvp invitation"
        />
      ),
      isAttendeeRsvpInvitationResendEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeRsvpInvitationResendEnabled"
          label="Resend Attendee Rsvp Invitation"
          helperText="Allow organizer to resend rsvp invitation"
        />
      ),
      isRsvpDuplicatedEmailEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isRsvpDuplicatedEmailEnabled"
          label="Enable Duplicated For Rsvp"
          helperText="Allow organizer to import rsvp with duplicated emails"
        />
      ),
      isRsvpStatusRevisionEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isRsvpStatusRevisionEnabled"
          label="Enable Change Rsvp status"
          helperText="Allow Rsvp Attendee to change status from rejected to accepted"
        />
      ),
      isBoothCategorySearchEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isBoothCategorySearchEnabled"
          label="Booth Category Search"
          helperText="Allow attendee to search booth categories"
        />
      ),
      isProductCategorySearchEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isProductCategorySearchEnabled"
          label="Product Category Search"
          helperText="Allow attendee to search product categories"
        />
      ),
      isProductStateConfigEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isProductStateConfigEnabled"
          label="Product Status Config"
          helperText="Allow organizer and exhibitor update product status"
        />
      ),
      isProductVideoWatermarkEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isProductVideoWatermarkEnabled"
          label="Product Video Watermark Config"
          helperText="Allow product video to attach watermark"
        />
      ),
      isOnboardingVideoSettingEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isOnboardingVideoSettingEnabled"
          label="Onboarding Video Setting"
          helperText="Enable Onboarding Video Setting when user enter the webniar or discussing group"
        />
      ),
      isPromoCodeEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isPromoCodeEnabled"
          label="Promo Code"
          helperText="Enable Promo Code feature for Event Ticket"
        />
      ),
      isPromoCodeAppliedToTicketEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isPromoCodeAppliedToTicketEnabled"
          label="Promo Code Quota Count In Per Ticket"
          helperText="promo code quota count in per ticket instead of per order"
        />
      ),
      isPhysicalSessionPermissionControlEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isPhysicalSessionPermissionControlEnabled"
          label="Physical Session Permission Control"
          helperText="Enable Physical Session Permission Control to display the new feature"
        />
      ),
      isExtendedAttendeeCheckInMethodEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isExtendedAttendeeCheckInMethodEnabled"
          label="Enable extended attendee check-in method"
          helperText="Enable extended attendee check-in method"
        />
      ),
      isOutreachManualEmailEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isOutreachManualEmailEnabled"
          label="Outreach Manual Email"
          helperText="Enable OutreachManual Email"
        />
      ),
      isOutreachEmailCopyEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isOutreachEmailCopyEnabled"
          label="Outreach Email Copy enabled"
          helperText="Enable outreach email copy/paste feature"
        />
      ),
      isExtendedOutreachEmailStatisticsEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isExtendedOutreachEmailStatisticsEnabled"
          label="Manual Email Not Clicked and Unsubscribed Statistics"
          helperText="Enable not clicked and unsubscribed statistics in email dashboard"
        />
      ),
      isAutomatedEmailUpgradedEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAutomatedEmailUpgradedEnabled"
          label="Automated email V2"
          helperText="Automated email enhancement include automated email list page, edit page, preview page and dashboard page."
        />
      ),
      isAttendeeFirstNameAndLastNameEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeFirstNameAndLastNameEnabled"
          label="Attendee First Name And Last Name"
          helperText="Enabled attendee first name and last name, including registration flow, import attendee, attendee first name and last name email merge tag."
        />
      ),
      isAttendeeSecondaryEmailsEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeSecondaryEmailsEnabled"
          label="Attendee Secondary Email"
          helperText="Enabled attendee secondary email, including email cc list, registration flow, import attendee."
        />
      ),
      isContentI18nEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isContentI18nEnabled"
          label="Enable Content I18n"
        />
      ),
      isCsvImportEncodingDetectionDisabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCsvImportEncodingDetectionDisabled"
          label="Enable CSV Import Encoding Windows1252, only for SCMP temp usecase, SHOULD NOT APPLY TO ANY OTHER EVENT OR ORGANIZATION"
        />
      ),
      isSpeakerPageEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isSpeakerPageEnabled"
          label="Enable Speaker Page"
        />
      ),
      isCheckInAppEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCheckInAppEnabled"
          label="Enable Check In App"
        />
      ),
      isMultipleTrackSessionEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isMultipleTrackSessionEnabled"
          label="Enable Multiple Track Session"
        />
      ),
      isLeadCaptureEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isLeadCaptureEnabled"
          label="Enable Lead Capture"
        />
      ),
      isLeadCaptureBusinessCardEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isLeadCaptureBusinessCardEnabled"
          label="Enable Lead Capture Business Card"
        />
      ),
      isLobbyEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isLobbyEnabled"
          label="Enable Lobby"
        />
      ),
      isBoothEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isBoothEnabled"
          label="Enable Booth"
        />
      ),
      isWebinarAuditoriumEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWebinarAuditoriumEnabled"
          label="Enable Webinar Auditorium"
        />
      ),
      isVenueCustomizationEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isVenueCustomizationEnabled"
          label="Enable Venue Customization"
        />
      ),
      isProductCatalogEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isProductCatalogEnabled"
          label="Enable Product Catalog"
        />
      ),
      isWebinarOnDemandSessionEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWebinarOnDemandSessionEnabled"
          label="Enable Webinar On Demand Session"
        />
      ),
      isWltHighQualityAudioEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWltHighQualityAudioEnabled"
          label="Enable WLT High Quality Audio"
        />
      ),
      isRealTimeInEventAnalyticsEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isRealTimeInEventAnalyticsEnabled"
          label="Enable Real Time In Event Analytics"
        />
      ),
      isMultiRegFormRegisteredCountEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isMultiRegFormRegisteredCountEnabled"
          label="Enable Multi Reg Form Registered Count"
        />
      ),
      isWltReplayEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWltReplayEnabled"
          label="Enable WLT Replay"
        />
      ),
      isAttendeeProfileCustomizationEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeProfileCustomizationEnabled"
          label="Enable Attendee Profile Customization"
        />
      ),
      isSpeedNetworkingEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isSpeedNetworkingEnabled"
          label="Enable Speed Networking"
        />
      ),
      isSponsorEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isSponsorEnabled"
          label="Enable Sponsor"
        />
      ),
      isMagicLinkEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isMagicLinkEnabled"
          label="Magic Link Enabled"
        />
      ),
      isMeetupAlwaysOnEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isMeetupAlwaysOnEnabled"
          label="Enable Meetup Always ON for Attendees"
          helperText="Enable Meetup Always ON for Attendees"
        />
      ),
      isRegisteredAttendeesOnPeopleListEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isRegisteredAttendeesOnPeopleListEnabled"
          label="Include Registered Attendees on People List"
        />
      ),
      isZendeskOnPortalSideEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isZendeskOnPortalSideEnabled"
          label="Zendesk On Portal Side Enabled"
        />
      ),
      isBoothRepresentativeListEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isBoothRepresentativeListEnabled"
          label="Booth Representative List Enabled"
        />
      ),
      isGeneralSearchEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isGeneralSearchEnabled"
          label="General Search Enabled"
        />
      ),
      isAttendeeExplorerEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeExplorerEnabled"
          label="Attendee Explorer Enabled"
          helperText="For internal event usage only"
        />
      ),
      isEventPageCustomizationEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isEventPageCustomizationEnabled"
          label="Event Page Customization Enabled"
        />
      ),
      isOverRegistrationEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isOverRegistrationEnabled"
          label="Over Registration Enabled"
        />
      ),
      isOverrideBoothContentConfigEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isOverrideBoothContentConfigEnabled"
          label="Booth Content Image Set Config"
          helperText="Enable image set config for each booth, instead of obeying template config"
        />
      ),
      isSimplifiedRegistrationFlowEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isSimplifiedRegistrationFlowEnabled"
          label="Enable simplified attendee registration flow"
          helperText="Enable simplified attendee registration flow"
        />
      ),
      isRegistrationFlowV3Enabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isRegistrationFlowV3Enabled"
          label="Enable registration flow V3"
          helperText="Enable registration flow V3"
        />
      ),
      isWidgetRegistrationFlowV3Enabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWidgetRegistrationFlowV3Enabled"
          label="Enable widget for registration flow V3"
          helperText="Enable widget for registration flow V3"
        />
      ),
      isInEventRealtimeDashboardEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isInEventRealtimeDashboardEnabled"
          label="Event Realtime Dashboard"
        />
      ),
      isAttendeeExportingEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeExportingEnabled"
          label="Attendee Exporting"
        />
      ),
      isAttendeeExportWithAttendanceEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeExportWithAttendanceEnabled"
          label="Attendee Exporting With Session Attendance(isAttended)"
        />
      ),
      isInstantPollEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isInstantPollEnabled"
          label="Event Instant Polling"
        />
      ),
      isGamificationBadgeCollectionEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isGamificationBadgeCollectionEnabled"
          label="Gamification Badge Collection"
        />
      ),
      isInstantQnAEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isInstantQnAEnabled"
          label="Instant Q&A"
        />
      ),
      isConcurrentLoginDisabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isConcurrentLoginDisabled"
          label="Disable Concurrent Login"
        />
      ),
      isWebhookEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWebhookEnabled"
          label="Webhook Integration"
        />
      ),
      isAttendeeGenerationEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeGenerationEnabled"
          label="Attendee Generation"
          helperText="Enable attendee generation feature"
        />
      ),
      isAttendeeGenerationPaidEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeGenerationPaidEnabled"
          label="Attendee Generation Paid Version"
          helperText="Enabled attendee generation paid version feature"
        />
      ),
      isEmailSendingEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isEmailSendingEnabled"
          label="Enable Email Sending"
          helperText="Enable Email Sending, block free plan due to spam"
        />
      ),
      isEnhancedDefaultLocaleEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isEnhancedDefaultLocaleEnabled"
          label="Enable Enhanced Default Locale"
        />
      ),
      isMagicLinkRetrieveFormEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isMagicLinkRetrieveFormEnabled"
          label="Enable Magic Link Retrieve Form"
        />
      ),
      isAttendeeDuplicatedOrEmptyEmailEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeDuplicatedOrEmptyEmailEnabled"
          label="Enable Duplicated Or Empty Email For Attendee"
        />
      ),
      isAnonymousAttendeeEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAnonymousAttendeeEnabled"
          label="Enable Attendee Generated From The Registration Form Without User Id"
        />
      ),
      isShowingEventSupportedLocalesOnlyEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isShowingEventSupportedLocalesOnlyEnabled"
          label="Show event supported locales only"
          helperText="Enable showing event supported locales only"
        />
      ),
      isCustomFieldImportEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCustomFieldImportEnabled"
          label="Support attendee custom field import"
          helperText="Enable attendee custom field import"
        />
      ),
      isWltRecordingBackupEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWltRecordingBackupEnabled"
          label="Enable WLT Recording Backup merchanism"
          helperText="Enable WLT Recording Backup merchanism"
        />
      ),
      isPhysicalCheckInCustomNameBadgeEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isPhysicalCheckInCustomNameBadgeEnabled"
          label="Physical Check-in Custom Namebadge"
          helperText="Enable customize namebadge"
        />
      ),
      isCheckInAppLoginQRCodeV2Enabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCheckInAppLoginQRCodeV2Enabled"
          label="Check-in App Login QR code V2"
          helperText="Enable check-in app login QR code v2"
        />
      ),
      isCheckInAppFirstNameAndLastNameV2Enabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCheckInAppFirstNameAndLastNameV2Enabled"
          label="Check-in App Support First Name and Last Name V2"
          helperText="Enable check-in app support first name and last name in nameBadge and customLine"
        />
      ),
      isCheckInAppAttendeeTagEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCheckInAppAttendeeTagEnabled"
          label="Check-in App Support Tag"
          helperText="Enable check-in app support tag in breakout session permission, nameBadge and customLine"
        />
      ),
      isPortalDashboardV2Enabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isPortalDashboardV2Enabled"
          label="Portal Dashboard V2 Enabled"
          helperText="Enable eSaaS portal dashboard tidy up with some new design and enhanced calculations"
        />
      ),
      isAttendeeRsvpInvitationLinkV2Enabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeRsvpInvitationLinkV2Enabled"
          label="Attendee RSVP Invitation Link V2 Enabled"
          helperText="Enable attendee RSVP list invitation link v2"
        />
      ),
      isCheckInAppBadgeEditorV2Enabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCheckInAppBadgeEditorV2Enabled"
          label="Check-in App Badge Editor V2 Enhancement"
          helperText="Enable Badge Editor V2 to support multiple line, abandoned text auto resize, and printer box capacity"
        />
      ),
      isWhiteLabelEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWhiteLabelEnabled"
          label="Hide eventx brand"
          helperText="Enable to hide eventx brand"
        />
      ),
      isAttendeeHybridStatusEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeHybridStatusEnabled"
          label="Enable Attendee Hybrid Status"
          helperText="Enable Attendee Hybrid Status"
        />
      ),
      isAttendeeIndividualImportingEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeIndividualImportingEnabled"
          label="Enable attendee individual importing"
        />
      ),
      isAttendeeShortenQRCodeTokenEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isAttendeeShortenQRCodeTokenEnabled"
          label="Enable numerical QR Code"
        />
      ),
      isBulkUpdateAttendeeEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isBulkUpdateAttendeeEnabled"
          label="Enable bulk update attendee"
          helperText="Enable bulk update attendee"
        />
      ),
      isCustomFieldMergeTagsEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCustomFieldMergeTagsEnabled"
          label="Enable Custom Field Merge Tags on Manual Email"
        />
      ),
      isCustomFieldFileUploadEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCustomFieldFileUploadEnabled"
          label="Enable Custom Field File Upload on Reg Form"
        />
      ),
      isCheckInAppRequiredFieldsSettingEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isCheckInAppRequiredFieldsSettingEnabled"
          label="Enable Check-In App required fields setting"
        />
      ),
      isSidebarCollapseButtonEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isSidebarCollapseButtonEnabled"
          label="Enable event site right sidebar collapse button"
          helperText="Enable event site right sidebar collapse button"
        />
      ),
      isRegFormConditionalLogicEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isRegFormConditionalLogicEnabled"
          label="Enable reg form conditional logic"
          helperText="Enable reg form conditional logic"
        />
      ),
      isDefaultRegFormEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isDefaultRegFormEnabled"
          label="Enable default reg form"
          helperText="Enable default reg form"
        />
      ),
      isDuplicateEmailRegistrationEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isDuplicateEmailRegistrationEnabled"
          label="Enable duplicate email registration for attendee"
          helperText="Enable duplicate email registration for attendee"
        />
      ),
      isApprovalMechanismConfigEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isApprovalMechanismConfigEnabled"
          label="Enable organizer to operate approval mechanism config"
          helperText="Enable organizer to operate approval mechanism config"
        />
      ),
      isMeetupEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isMeetupEnabled"
          label="Enable Meetup"
          helperText="Enable Meetup"
        />
      ),
      isEventEntrySettingEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isEventEntrySettingEnabled"
          label="Enable Event Entry Setting"
          helperText="Enable Event Entry Setting"
        />
      ),
      isUnifiedAccessEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isUnifiedAccessEnabled"
          label="Enable Unified Access"
          helperText="Enable Unified Access"
        />
      ),
      isEventOfflinePaymentEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isEventOfflinePaymentEnabled"
          label="Event Offline Payment"
          helperText="Enabled event offline payment."
        />
      ),
      isWhatsappOutgoingMessageWithPhysicalSessionEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isWhatsappOutgoingMessageWithPhysicalSessionEnabled"
          label="Enable Sending Physical Session Details in WhatsApp"
          helperText="Toggle to include Physical Session Information in WhatsApp messages"
        />
      ),
      isTicketClassAddOnEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isTicketClassAddOnEnabled"
          label="Enable Ticket Class Add-On"
          helperText="Enable Ticket Class Add-On"
        />
      ),
      isMultiRegFormsWithDifferentTicketingEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isMultiRegFormsWithDifferentTicketingEnabled"
          label="Enable Ticket Setting per each Registeration Form"
          helperText="Enable Multiple Registration Forms With Different Ticketing"
        />
      ),
      isTicketQuantityPerOrderEditableEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isTicketQuantityPerOrderEditableEnabled"
          label="Enable Ticket Class Got Its Amount Limitation"
          helperText="Enable Ticket Got Its Amount Limitation"
        />
      ),
      isScheduleSessionEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isScheduleSessionEnabled"
          label="Schedule Session"
          helperText="Enabled schedule session"
        />
      ),
      isUnlayerOrderSummaryEmailEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isUnlayerOrderSummaryEmailEnabled"
          label="Enabled Unlayer Order Summary Email"
          helperText="Enabled to use Unlayer Order Summary Email"
        />
      ),
      isSkipEmailForFreeOrderEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isSkipEmailForFreeOrderEnabled"
          label="Skip Sending Order Summary Email For Free Order"
        />
      ),
      isSkipApprovalMechanismForRSVPEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isSkipApprovalMechanismForRSVPEnabled"
          label="Skip Approval Mechanism For RSVP Attendees"
        />
      ),
      isLeadFinderAIEmailEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isLeadFinderAIEmailEnabled"
          label="Enable Lead Finder AI Email"
          helperText="Enable Lead Finder AI Email"
        />
      ),
      isLeadFinderAILookalikePersonaEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isLeadFinderAILookalikePersonaEnabled"
          label="Enable Lead Finder AI Lookalike Persona"
          helperText="Enable Lead Finder AI Lookalike Persona"
        />
      ),
      isOutreachEmailHistoryEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isOutreachEmailHistoryEnabled"
          label="Enable Outreach Email History"
          helperText="Enable Outreach Email History"
        />
      ),
      isRegFormPurchaserNameEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isRegFormPurchaserNameEnabled"
          label="allow reg-form to submit purchaser name"
          helperText="allow purchaser to submit its name to db."
        />
      ),
      isPersonalizationMergeTagEnabled: (
        <BooleanConfigInput
          {...commonInputProps}
          fieldName="isPersonalizationMergeTagEnabled"
          label="Enable Personalization Merge Tags"
          helperText="Enable Personalization Merge Tags"
        />
      ),
    }),
    [commonInputProps],
  );

  const defaultEventFeatureLimitComponents = useMemo<
    Record<keyof EditableEventFeatureLimit, JSX.Element>
  >(
    () => ({
      aiEngineTDCRecommendationURL: (
        <TextConfigInput
          {...commonInputProps}
          fieldName="aiEngineTDCRecommendationURL"
          label="HKTDC AI engine Recommendation URL"
          helperText="custom AI engine API URL for hktdc event, please enter the specific recommendation url for this hktdc event, e.g., https://api-bmai-uat.hktdc.com/v1/recommendation/conferences/asgh/years/2324/participants/#{pid}/#{role}/recommendation"
        />
      ),
      attendeeTagsFieldId: (
        <TextConfigInput
          {...commonInputProps}
          fieldName="attendeeTagsFieldId"
          label="Attendee Tag field id"
          helperText="custom attendee tags for hktdc event, please enter the id of question field in registration form"
        />
      ),
      customAutomatedEmailSender: (
        <JSONConfigInput
          {...commonInputProps}
          fieldName="customAutomatedEmailSender"
          label="Custom Email Sender Json Field"
          helperText="custom email sender in auto confirmation email.<br/><strong>default</strong> will apply to Enum EmailCustomizableTemplateType emails.<br/>"
        />
      ),
      checkInAppDataSyncInterval: (
        <NumberConfigInput
          {...commonInputProps}
          fieldName="checkInAppDataSyncInterval"
          label="Check-in App Data Sync Interval"
          helperText="Check-in App's auto data sync frequency with a value in milliseconds."
        />
      ),
      wltRecordingQuality: (
        <AutocompleteConfigInput
          {...commonInputProps}
          fieldName="wltRecordingQuality"
          label="WLT Recording Quality"
          choices={[
            { id: '480p', name: '480p' },
            { id: '720p', name: '720p' },
            { id: '1080p', name: '1080p' },
          ]}
        />
      ),
      wltMaxStreamingQuality: (
        <AutocompleteConfigInput
          {...commonInputProps}
          fieldName="wltMaxStreamingQuality"
          label="WLT Max Streaming Quality"
          choices={[
            { id: '480p', name: '480p' },
            { id: '720p', name: '720p' },
            { id: '1080p', name: '1080p' },
          ]}
        />
      ),
      extraEntryTypes: (
        <AutocompleteArrayConfigInput
          {...commonInputProps}
          fieldName="extraEntryTypes"
          label="Extra Entry Types"
          choices={[{ id: 'by_invite_only', name: 'BY_INVITE_ONLY' }]}
        />
      ),
      webinar3rdPartyIntegrations: (
        <AutocompleteArrayConfigInput
          {...commonInputProps}
          fieldName="webinar3rdPartyIntegrations"
          label="Webinar 3rd Party Integrations"
          choices={[
            { id: 'kahoot', name: 'KAHOOT' },
            { id: 'google_doc', name: 'GOOGLE_DOC' },
            { id: 'kudo', name: 'KUDO' },
            { id: 'slido', name: 'SLIDO' },
          ]}
        />
      ),
      liveStreamingDisabledTicketIds: (
        <TextConfigInput
          {...commonInputProps}
          fieldName="liveStreamingDisabledTicketIds"
          label="Disabled live streaming base on ticket ids"
          nullable="true"
        />
      ),
      maxEventDurationInHour: (
        <NumberConfigInput
          {...commonInputProps}
          fieldName="maxEventDurationInHour"
          label="Max Event Duration In Hour"
          nullable="true"
        />
      ),
      maxNumberOfEventManagers: (
        <NumberConfigInput
          {...commonInputProps}
          fieldName="maxNumberOfEventManagers"
          label="Max Number Of Event Managers"
        />
      ),
      maxNumberOfNetworkingLoungeTables: (
        <NumberConfigInput
          {...commonInputProps}
          fieldName="maxNumberOfNetworkingLoungeTables"
          label="Max Number Of Networking Lounge Tables"
        />
      ),
      maxNumberOfNetworkingLoungeSeatsPerTable: (
        <NumberConfigInput
          {...commonInputProps}
          validate={validateMaxNumberOfNetworkingLoungeSeatsPerTable}
          fieldName="maxNumberOfNetworkingLoungeSeatsPerTable"
          label="Max Number Of Networking Lounge Seats Per Table"
        />
      ),
      maxVideoLibraryFileSizeLimitInMb: (
        <NumberConfigInput
          {...commonInputProps}
          fieldName="maxVideoLibraryFileSizeLimitInMb"
          label="Max Video Library File Size Limit In MB"
        />
      ),
      maxNumberOfBulkUpdateAttendees: (
        <NumberConfigInput
          {...commonInputProps}
          fieldName="maxNumberOfBulkUpdateAttendees"
          label="Max attendee number of bulk update"
          helperText="Max attendee number of bulk update"
        />
      ),
      maxNumberOfAttendees: (
        <NumberConfigInput
          {...commonInputProps}
          fieldName="maxNumberOfAttendees"
          label="Max Number Of Attendees(deprecated)"
          helperText="Max Number Of Attendees(deprecated)"
        />
      ),
      maxNumberOfEventRegistrants: (
        <NumberConfigInput
          {...commonInputProps}
          fieldName="maxNumberOfEventRegistrants"
          label="Max Number Of Event Registrants(deprecated)"
          helperText="NOTE: Only on EVENT level, not organization level(deprecated)"
        />
      ),
    }),
    [commonInputProps],
  );

  const pricingPlanFeatureFlagComponents = useMemo(() => {
    const components = pick(
      defaultEventFeatureFlagComponents,
      pricingPlanFeatureConfigKeys,
    );

    const sortedKeys = Object.keys(defaultEventFeatureFlagComponents) as Array<
      keyof EditableEventFeatureFlag
    >;

    return sortedKeys.reduce<Record<string, JSX.Element>>(
      (sortedComponents, key) => {
        if (components[key]) {
          sortedComponents[key] = components[key]!;
        }

        return sortedComponents;
      },
      {},
    );
  }, [defaultEventFeatureFlagComponents]);

  const pricingPlanFeatureLimitComponents = useMemo(() => {
    const components = pick(
      defaultEventFeatureLimitComponents,
      pricingPlanFeatureConfigKeys,
    );

    const sortedKeys = Object.keys(defaultEventFeatureLimitComponents) as Array<
      keyof EditableEventFeatureLimit
    >;

    return sortedKeys.reduce<Record<string, JSX.Element>>(
      (sortedComponents, key) => {
        if (components[key]) {
          sortedComponents[key] = components[key]!;
        }

        return sortedComponents;
      },
      {},
    );
  }, [defaultEventFeatureLimitComponents]);

  const styles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
  };

  switch (mode) {
    case 'event':
      return (
        <div style={styles}>
          {Object.entries(defaultEventFeatureFlagComponents).map(
            ([key, component]) => (
              <Fragment key={key}>{component}</Fragment>
            ),
          )}
          <br />
          {Object.entries(defaultEventFeatureLimitComponents).map(
            ([key, component]) => (
              <Fragment key={key}>{component}</Fragment>
            ),
          )}
        </div>
      );
    case 'pricing-plan':
      return (
        <div style={styles}>
          {Object.entries(pricingPlanFeatureFlagComponents).map(
            ([key, component]) => (
              <Fragment key={key}>{component}</Fragment>
            ),
          )}
          <br />
          {Object.entries(pricingPlanFeatureLimitComponents).map(
            ([key, component]) => (
              <Fragment key={key}>{component}</Fragment>
            ),
          )}
        </div>
      );
    default:
      return null;
  }
};
